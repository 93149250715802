import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Store } from "../Store";
import { toast } from "react-toastify";
import { getError } from "../utils";
import ReCAPTCHA from "react-google-recaptcha";

export default function SigninPage() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("");
  const [recaptchaLoading, setRecaptchaLoading] = useState(true);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const loadRecaptchaKey = async () => {
      try {
        const response = await axios.get("/api/captcha/recaptcha-key");
        const recaptchaKey = response.data.recaptchaKey;
        setRecaptchaSiteKey(recaptchaKey);
        setRecaptchaLoading(false);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    loadRecaptchaKey();
  }, []);

  const redirectTo = (path) => {
    navigate(path);
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  useEffect(() => {
    if (userInfo) {
      redirectTo(redirect);
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!recaptchaValue) {
        toast.error("Veuillez compléter le captcha");
        return;
      }
      const { data } = await axios.post("/api/users/signin", {
        email,
        password,
      });
      ctxDispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...data,
          tokenExpirationDate: new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
        })
      );
      redirectTo(redirect || "/");
      toast.success(`Vous êtes connecté`, { autoClose: 1500 });
    } catch (err) {
      toast.error(getError(err));
    }
  };

  return (
    <Container className="my-5 small-container ">
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <Row>
        <Col md={8} className="offset-md-2 bg3 p-5 rounded shadow">
          <h1 className="my-3 text-center">Connexion</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control
                type="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center" controlId="captcha">
              <div className="d-flex justify-content-center align-items-center">
                {recaptchaLoading ? (
                  <div>Loading reCAPTCHA...</div>
                ) : (
                  <div>
                    <ReCAPTCHA
                      sitekey={recaptchaSiteKey}
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                )}
              </div>
            </Form.Group>

            <div className="mb-3">
              <Button
                type="submit"
                className="bg1 w-100"
                variant="outline-light"
              >
                Connexion
              </Button>
            </div>
            <div className="d-flex justify-content-between">
              <div className="mb-3">
                Nouveau client ?{" "}
                <Link to={`/signup?redirect=${redirect}`}>
                  Inscrivez-vous !
                </Link>
              </div>
              <div className="mb-3">
                <Link to={"/forgot-password"}>Mot de passe oublié</Link>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
