import React, { useEffect, useReducer, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import LoadingBox from "./LoadingBox";

function CheckoutForm({ order, reducer, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(true); // Nouvel état pour suivre le processus de paiement
  const [message, setMessage] = useState("");
  const [{}, dispatch] = useReducer(reducer, {});

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/order/${order._id}`,
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!stripe) {
      setIsPaymentProcessing(false); // Arrête le loader si Stripe n'est pas prêt
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      setIsPaymentProcessing(false); // Arrête le loader si aucun clientSecret n'est trouvé
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          dispatch({ type: "IS_PAID_SUCCESS" });
          toast.success("Paiement accepté, merci !");
          setTimeout(async () => {
            const paymentId = paymentIntent.id;
            await axios.post("/api/payments/stripe/check", {
              paymentId,
              clientSecret: clientSecret,
              orderId: order._id,
            });
            setTimeout(() => {
              onSuccess();
              window.history.pushState(
                {},
                document.title,
                window.location.pathname
              );
              setLoading(false);
            }, 3000);
          }, 1000);
          break;
        case "requires_payment_method":
          toast.error("Le paiement a échoué, merci de réessayer.");
          setIsPaymentProcessing(false); // Arrête le loader si le paiement échoue
          break;
        default:
          dispatch({ type: "IS_PAID_FAIL" });
          toast.error("Le paiement a échoué, merci de réessayer.");
          onSuccess();
          setIsPaymentProcessing(false); // Arrête le loader pour les autres cas
          break;
      }
    });
  }, [stripe, order._id, onSuccess]);

  if (isPaymentProcessing) {
    return (
      <LoadingBox
        size="lg"
        animation="border"
        className="m-3 d-flex justify-content-center"
      />
    );
  }

  return (
    <Form onSubmit={handleSubmit} style={{ maxWidth: 400 }}>
      <PaymentElement />
      {message && (
        <div className="alert alert-danger mt-2 text-center" role="alert">
          {message}
        </div>
      )}
      <div className="my-2 text-center w-full">
        {loading ? (
          <LoadingBox
            size="lg"
            animation="border"
            className="m-3 d-flex justify-content-center"
          />
        ) : (
          <Button
            type="submit"
            className="w-100 mt-3"
            disabled={!stripe || !elements || loading}
          >
            Payer
          </Button>
        )}
      </div>
    </Form>
  );
}

export default CheckoutForm;
