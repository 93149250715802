import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import { Store } from "../Store";

const PUBLIC_KEY =
  "pk_live_51Lqh9bC3E0acuJ6JOuGP2H5zgOGlWyMy7y7skJecxA1qr7tHt6r1Rpv2eqaGmc8slynF7fHtm2fyLfQ9UCMBEV9X00Pvt5Nsy7";
const stripePromise = loadStripe(PUBLIC_KEY);
// const PUBLIC_KEY =
//   "pk_test_51Lqh9bC3E0acuJ6Jju3YfmPFiSUmjmBsyJAmadK737WFxz8i0ohv84MRXGomknkDoi5uyr4Sygyt5kZEq7CNvTDv00860YzrVi";
// const stripePromise = loadStripe(PUBLIC_KEY);

function StripeContainer({ order, reducer, onSuccess }) {
  const [clientSecret, setClientSecret] = useState("");
  const { state } = useContext(Store);
  const { userInfo } = state;
  useEffect(() => {
    async function fetchClientSecret() {
      const response = await axios.post(
        "/api/payments/stripe/create-payment-intent",
        { amount: order.totalPrice }, // This is the body of the request
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      // Correction ici: utiliser response.data au lieu de response.json()
      setClientSecret(response.data.clientSecret);
    }

    fetchClientSecret();
  }, [order.totalPrice, userInfo.token]);

  return (
    <div>
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm
            order={order}
            clientSecret={clientSecret}
            reducer={reducer}
            onSuccess={onSuccess}
          />
        </Elements>
      )}
    </div>
  );
}

export default StripeContainer;
